import Experiments from '@wix/wix-experiments'

export const fetchExperiments = async (): Promise<Experiments> => {
  const experiments = new Experiments({
    scope: 'wix-events-ui',
  })

  await experiments.ready()
  return experiments
}

export const pagePanelEventsEnabled = (experiments: Experiments) => experiments.get('pagesPanelEventsTab') === 'true'
